import axios from 'axios';
import he from 'he';
import { useEffect } from 'react';
import { useState } from 'react';
import { Container, InputGroup, FormControl, Button, Spinner, Card, Row, Col } from 'react-bootstrap';
import { BsFillBookmarkPlusFill, BsSearch } from 'react-icons/bs';
import api from './api';
import { store, auth } from './store';

const Browse = ({ }) => {
    const [books, setBooks] = useState([]);
    const [searching, setSearching] = useState(false);
    const [query, setQuery] = useState('');

    const search = () => {
        setSearching(true);
        axios.get('https://www.googleapis.com/books/v1/volumes', { params: { q: query, maxResults: 40, printType: 'books' } }).then(r => {
            setBooks(r.data.items);
            setSearching(false);
        });
    };

    const [trackers, setTrackers] = useState(null);
    useEffect(() => {
        api.get('/trackers').then(r => setTrackers(r.data.map(combined => combined.tracker))).catch(e => setTrackers([]));
    }, []);

    const trackBook = book => {
        if (trackers.find(t => t.book_id === book.id)) {
            alert("Already tracked.");
            return;
        }

        if (!store.getState().auth.userInfo) {
            store.dispatch(auth.actions.openLogin());
            return;
        }

        api.post('/trackers', {
            book_id: book.id,
            title: book.volumeInfo.title,
            subtitle: book.volumeInfo.subtitle ?? '',
            pages: book.volumeInfo.pageCount,
            description: he.decode((book.volumeInfo.description ?? '')),
        }).then(r => {
            setTrackers([r.data, ...trackers]);
        });
    };

    if (!trackers) {
        return (
            <Container className="p-lg-5 p-2 pt-3 mb-4 bg-light rounded-3">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    return (
        <Container className="p-lg-5 p-2 pt-3 mb-4 bg-light rounded-3">
            <h1 className="header"><img src="/books-icon-48.png" style={{ marginRight: 8, height: 36, marginBottom: 8 }} /> Reading Tracker App</h1>
            <p style={{ textAlign: 'center' }}>Track your progress in books, set goals, see your activity chart, <i>and more!</i></p>

            <br />

            <form>
                <InputGroup className="">
                    <FormControl placeholder="Enter a book title..." disabled={searching} value={query} onChange={e => setQuery(e.target.value)} />
                    <Button variant="outline-secondary" type="submit" disabled={searching} onClick={() => search()}>
                        {searching ? <Spinner as="span" animation="border" size="sm" style={{ marginRight: 8 }} /> : <BsSearch />}
                    </Button>
                </InputGroup>

                <span className="text-muted" style={{ fontSize: 12 }}>Book data powered by <a href="https://books.google.com/" target="_blank">Google Books</a>.</span>
            </form>

            <hr />

            <Row>
                {books.map(book => (
                    <Col key={book.id} xl={4} style={{ marginBottom: 24 }}>
                        <BookCard book={book} tracked={trackers.find(t => t.book_id === book.id)} trackBook={() => trackBook(book)} />
                    </Col>
                ))}
            </Row>

        </Container>
    );
}

const BookCard = ({ book, tracked, trackBook }) => {
    const { volumeInfo: { publishedDate, description, pageCount, title, subtitle, imageLinks } } = book;
    const thumbUrl = (imageLinks?.thumbnail ?? 'https://via.placeholder.com/120x180?text=No+preview').replace('http:', 'https:');
    const brief = he.decode((description ?? 'No description available.').substr(0, 200));

    return (
        <Card style={{ minHeight: 300, height: '100%' }}>
            <Card.Header as="span">
                <h6 style={{ marginBottom: 0 }}>{title}</h6>
                <div style={{ fontSize: 12, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{subtitle}</div>
            </Card.Header>

            <Card.Body>
                <Card.Text style={{ marginTop: 8, fontSize: 14 }}>
                    <img src={thumbUrl} style={{ float: 'left', paddingRight: 8 }} />
                    {brief}
                </Card.Text>
            </Card.Body>

            <Card.Footer className="text-muted" style={{ fontSize: 12 }}>
                <span style={{ float: 'right' }}>
                    <Button disabled={tracked} onClick={trackBook} variant="primary" size='sm' style={{ marginTop: 2 }}><BsFillBookmarkPlusFill /> Create tracker</Button>
                </span>

                <span>{publishedDate}<br /><b>{pageCount} pages</b></span>
            </Card.Footer>
        </Card>
    );
}

export default Browse;
