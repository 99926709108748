import { Nav, Navbar, Container } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { SignInButton } from './SignInButton';

const AppNav = () => {
    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Link to="/" className="navbar-brand">
                    <Navbar.Brand href="#home">
                        <img alt="" src="/books-icon-48.png" width="30" height="30" className="d-inline-block align-top" style={{ marginRight: 8 }} /> Reading Tracker
                    </Navbar.Brand>
                </Link>

                <Navbar.Toggle aria-controls="navbarScroll" />

                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto" navbarScroll>
                        <NavLink to="/" className="nav-link">Browse</NavLink>
                        <NavLink to="/trackers" className="nav-link">Trackers</NavLink>
                    </Nav>

                    <div className="justify-content-end">
                        <SignInButton />
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default AppNav;
